<template>
  <app-overlay>
    <searchable-table
      :items="pendingRequests"
      :fields="fields"
      hover
      @row-clicked="showDetails"
    >
      <template #status="{ item }">
        <b-badge :variant="$variants[item.status]">
          {{ $capitalize(item.status) }}
        </b-badge>
      </template>
      <template #actions="{ item }">
        <app-icon-button
          v-b-tooltip.hover.bottom="'Ara'"
          icon="PhoneIcon"
          variant="gradient-success"
          @click="call(item)"
        />
      </template>
    </searchable-table>
  </app-overlay>
</template>

<script>
export default {
  data() {
    return {
      fields: [
        { key: 'phone', label: 'Telefon' },
        { key: 'createdAt', label: 'Oluşturulma Tarihi', formatDateTime: true },
        { key: 'status', label: 'Durum' },
        { key: 'actions', label: 'İşlemler' },
      ],
    }
  },
  computed: {
    pendingRequests() {
      return this.$store.getters.pendingCallRequests
    },
  },
  mounted() {
    this.$socket.on('NEW_WEB_CALL_REQUEST', data => {
      this.$store.commit('newWebCallRequest', data)
    })
    this.$socket.on('WEB_CALL_REQUEST_UPDATED', data => {
      this.$store.commit('updateWebCallRequest', data)
    })
    this.getItems()
  },
  destroyed() {
    this.$socket.off('NEW_WEB_CALL_REQUEST')
    this.$socket.off('WEB_CALL_REQUEST_UPDATED')
  },
  methods: {
    getItems() {
      this.$store.dispatch('getPendingCallRequests')
    },
    call(item) {
      this.$axios
        .post(`/central/web-call-request/${item?._id}/call`, { loading: 'table' })
        .then(result => {
          this.$emitter.$emit('Notification', {
            variant: 'success',
            title: 'İşlem Başarılı',
            message: result.data.message,
          })
        })
        .catch(err => {
          this.$emitter.$emit('Notification', {
            variant: 'danger',
            title: 'İşlem Başarısız',
            message: err.response.data.message,
          })
          console.log(err)
        })
    },
    showDetails(item) {
      this.$showAppModal({
        component: () => import('./details/WebCallRequestDetailTabs.vue'),
        size: 'lg',
        centered: false,
        item,
        title: `${item?.phone} - Arama Detayları`,
      })
    },
  },
}
</script>

<style></style>
