<template>
  <b-custom-tabs id="CentralTabs" :tabs="tabs" lazy />
</template>

<script>
import WebCallRequestList from "./WebCallRequests/WebCallRequestList.vue";

export default {
  data() {
    return {
      tabs: [{ title: "Arama Bekleyenler", component: WebCallRequestList }, { title: "Gelen Arama" }, { title: "Giden Arama" }],
    };
  },
};
</script>

<style></style>
